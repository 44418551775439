export interface UnitSettings {
  autoCloseAfterTime: boolean;
  autoCloseTimeInHours: number;
  autoCloseStatus2: boolean;
  autoDeleteApager: boolean;
  autoDeleteApagerTimeInHours: number;
  disabled: boolean;
  useFeedback: boolean;
  customFeedbackId: string;
  hasCustomFeedback: boolean;
  tracing: boolean;
  enabledForManualAlarm: boolean;
  addressCalculationActive: boolean;
  addressCalculationModes: EAddressCalculationMode[];
  addressCalculationField: string;
  directionCardField: string;
  overrideDefaultDoubleAlarmTime: boolean;
  doubleAlarmTime: number;
  filtered: boolean;
  useForLeaderboard: boolean;
  selectedSplitter: Splitter;
  unitName: string;
  unitCode: string;
  note: string;
  disableAaoSearch: boolean;
  sammelUnitSettings: SammelUnitSettings;
  expressCodes: string[];
  wachalarmSettings: WachalarmSettings;
  buildingCalculationModeCheck: EBuildingCalculationModeCheck;
}

export interface SammelUnitSettings {
  codeToSammel: string[],
  triggerCodes: string[]
}

export interface WachalarmSettings {
  relatedUnitCodes: string[],
  triggerCodes: string[]
}

export enum EAddressCalculationMode {
  COORDINATES_DEZ = 'COORDINATES_DEZ',
  COORDINATES_HEX = 'COORDINATES_HEX',
  COORDINATES_GK = 'COORDINATES_GK',
  STREET_DICTIONARY = 'STREET_DICTIONARY',
  BUILDING = 'BUILDING'
}

export enum EBuildingCalculationModeCheck {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS'
}

export interface Unit {
  userId: string;
  id: string;
  name: string;
  code: string;
  settings: UnitSettings;
  type: EUnitType;
  hasQuickEdit: boolean;
  hasTracings: boolean;
  hasPipeline: boolean;
  editableByUser: boolean;
  hasProtocol: boolean;
  hasConnectedVehicle: boolean; // for Queues
  connectedVehicleName: string;
}

export interface EditUnit {
  name: string;
  code: string;
}

export enum EUnitType {
  DEFAULT = 'DEFAULT',
  SAMMEL = 'SAMMEL',
  EXPRESS = 'EXPRESS',
  WACHALARM_PRE = 'WACHALARM_PRE',
  WACHALARM_POST = 'WACHALARM_POST',
  STATUS = 'STATUS',
  AVAILABILITY_RULE = 'AVAILABILITY_RULE',
  CUSTOM = 'CUSTOM',
  QUEUE = 'QUEUE'
}

export interface UnitFilterResponse {
  filter?: string,
  numberOfUsers: number,
  users: UnitFilterEntry[]
}

export interface DeactivatedUnits {
  deactivatedUnits: number,
  allUnits: number
}

export interface UnitFilterEntry {
  username: string,
  userId: string,
  units: Unit[]
}

export class Splitter {
  public id: string;
  public name: string;
  public version: string;
  public author: string;
  versionDataMissing: boolean;
}

export interface AdminAddUnitRequest {
  name: string,
  code: string,
  type: EUnitType,
  connectedVehicleId: string;
}

export interface AddUnitRequest {
  name: string;
  unitType: EUnitType;
  connectedVehicleId: string;
}

export interface PipelineProtocolEntry {
  id: string,
  unitId: string,
  editor: string,
  change: string,
  timestamp: number,
  open: boolean
}