import {AssetStrength, EVehicleStatus, Vehicle, VehicleLocationResponse, VehicleSimple} from "./vehicles.data";
import {EmergencyVehicleSimple} from "./emergency.data";
import {EInputFallbackCategory} from "./input.data";
import {ex} from "@fullcalendar/core/internal-common";
import {SimpleUnit} from "./admin.data";

export interface State {
  state: string;
  modified: number;
  closed: boolean;
  open: boolean;
}

export interface AlarmHistory {
  id: string,
  username: string,
  userId: string,
  unit: string,
  alarmdata: AlarmData,
  filtered: boolean,
  important: boolean,
  hasError: boolean
}

export interface Protocol {
  timestamp: any;
  type: EAlarmProtocolType;
  name?: string;
  message?: string;
  distance?: string;
  vehicleId?: string;
  address?: string;
  status?: string;
  functions?: string;
  groups?: string;
  feedback?: string;
  strength?: AssetStrength;
  unitName?: string;
}

export enum EAlarmProtocolType {
  START = 'START',
  END = 'END',
  CLOSED = 'CLOSED',
  STATUS = 'STATUS',
  FEEDBACK = 'FEEDBACK',
  RESPONSE = 'RESPONSE',
  PERSON_DISTANCE = 'PERSON_DISTANCE',
  CLOSE_ACTION = 'CLOSE_ACTION'
}

export enum APagerResponseStatus {
  OK = 'OK',
  OK_ASYMMETRIC = 'OK_ASYMMETRIC',
  OK_SYMMETRIC = 'OK_SYMMETRIC',
  NOT_FOUND = 'NOT_FOUND',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  DISABLED = 'DISABLED',
  NO_VALID_LICENCE = 'NO_VALID_LICENCE',
  NEW_PUBLIC_KEY = 'NEW_PUBLIC_KEY',
  PUSH_SENT = 'PUSH_SENT',
  RECEIVED_BY_DEVICE = 'RECEIVED_BY_DEVICE',
  PUSH_ERROR = 'PUSH_ERROR',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  FALLBACK_TRIGGERED = 'FALLBACK_TRIGGERED'
}

export interface APagerResponseEntry {
  displayName: string;
  result: PushAlarmSummaryResponseAggregated;
}

export interface PushAlarmSummaryResponseAggregated {
  internalProcessingTimeDelta: number;
  internalProcessingEndtimeTimestamp: any;
  apagerResponseStatus: APagerResponseStatus;
  email: string;
  extra?: any;
  alarmId: string;
  deviceType: string;
  errorState: boolean;
  fallbackTriggered: boolean;
}

export interface Statistics {
  countSuccessPushed: number;
  countSuccessReceived: number;
  countFailure: number;
  averageDurationPushed: number;
  ninetyfivePercentileDurationPushed: number;
  ninetyninePercentileDurationPushed: number;
  averageDurationReceived: number;
  ninetyfivePercentileDurationReceived: number;
  ninetyninePercentileDurationReceived: number;
}

export interface PushAlarmSummaryResponse {
  includesStatistics: boolean;
  entries: APagerResponseEntry[];
  statistics: Statistics;
}

export interface AlarmDataSimple {
  time: any;
  unit: string;
  alarmId: string;
  id: string;
  text: string;
  alarmType: string;
  important: boolean;
  error: boolean;
  warning: boolean;
  state?: State;
  dataState: string;
  keyword?: string;
  isNew: boolean;
  externalId: string;
  locationDest: string;
  vehicles: EmergencyVehicleSimple[];
  revisionActive: boolean;
  userId: string;
}

export interface AlarmData {
  isNew?: boolean;
  revisionActive: boolean;
  time: number;
  unit: string;
  code: string;
  alarmId: string;
  externalId?: any;
  id: string;
  text: string;
  alarmType: string;
  allowStatusProtocol: boolean;
  important: boolean;
  error: boolean;
  hasLiveImage: boolean;
  loggingId: number;
  hasState: boolean;
  state: State;
  locationDest: string;
  parameters: {};
  errorList: any[];
  warningList: any[];
  hasProtocol: boolean;
  protocol: Protocol[];
  hasCloseActions: boolean;
  closeActions: any[];
  hasExternalCosts: boolean;
  externalCosts: any[];
  hasVehicles: boolean;
  vehicles: AlarmVehicle[];
  hasPushAlarmSummaryResponse: boolean;
  pushAlarmSummaryResponse: PushAlarmSummaryResponse;
  alarmFlow?: AlarmFlow[];
  shortenedLocationMapId: string;
  fallbackCategory: EInputFallbackCategory;
  locationMap?: LocationMap;
}

export interface LocationMap {
  mapID: string;
  timestamp: string;
}

export interface AlarmFlow {
  name: string;
  organisation?: string;
  timestamp: string;
}

export interface AlarmVehicle {
  vehicleId: string;
  address: string;
  status: string;
  name: string;
  dispoInfo: string;
  timestamp: number;
  order: number;
  hasOrder: boolean;
  hasDispoInfo: boolean;
  hasVehicleId: boolean;
  jobNumber: string;
  patientNumber: string;
}

export interface AlarmDataPage {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  data: AlarmDataSimple[];
}


export interface Keyword {
  ident: string;
  keyword: string;
  description: string;
  additional: string;
  tracking: boolean
  category: string;
  color: string;
  ser: string;
  tts: string;
}

export interface PaginatedKeywordResponse {
  data: Keyword[];
  totalElements: number;
  totalPages: number;
  currentPage: number;
  length: number;
}

export interface AlarmSendData {
  message: string;
  keyword: string;
  keyword_misc: string;
  keyword_category: string;
  keyword_ident: string;
  keyword_color: string;
  keyword_description: string;
  keyword_ser: string;
  street: string;
  house: string;
  city: string;
  postalCode: string;
  building?: string;
  lat?: number;
  lng?: number;
  location_additional?: string;
  withStatistic: boolean;
  alarmType: string;
  quickEditActive: string;
  quickEditIDs: string;
  subric: string;
  building_id: string;
  city_abbr?: string;
  building_type: string;
  building_ident: string;
  generateExternalId: boolean;
}

export interface AlarmRequest {
  data: AlarmSendData;
  vehicles: Vehicle[]
  units: string;
  externalId: string;
}

export interface AlarmRequestSimple {
  data: AlarmSendData;
  vehicles: VehicleSimple[];
  units: string;
  externalId: string;
  retriggered?: boolean;
}

export interface AlarmScheduleRequestSimple {
  data: AlarmSendData;
  vehicles: VehicleSimple[];
  units: string;
  externalId: string;
  retriggered?: boolean;
  schedule: ScheduleRequest;
}

export interface ScheduleRequest {
  infoMessageScheduleType: string,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean,
  sunday: boolean,
  timeOnce: Date,
  timeRepeating: string
}

export interface AlarmScheduleResponse {
  alarmScheduleId: string,
  units: SimpleUnit[],
  vehicles: VehicleSimple[],
  keyword: string,
  message: string,
  owner: string,
  type: string,
  creationTime: Date,
  schedule: ScheduleRequest;
}

export interface AlarmScheduleDataPage {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  data: AlarmScheduleResponse[];
}

export interface AlarmFakerRequest {
  unitIds: string[];
  update: boolean;
  vehicles: VehicleSimple[];
  addVehiclesForFakeAlarm: boolean;
}

export interface SearchObjectResponse {
  abs: number;
  distance: number;
  address: string;
  street: string;
  city: string;
  house: string;
  postalCode: string;
  additional: string;
  building: string;
  abbreviation: string;
  listOfNearObjects: string;
  confidence: number;
  overwriteCoordinates: boolean;
  id: string;
  ident: string;
  additionalIdent: string[];
  alarmObjectType: string;
  url: string;
  latitude: number;
  longitude: number;
  alarmObject: boolean;
  tvp: string;
  gps: string;

}

export interface VehicleAssignedAlarm {
  externalId: string;
  vehicleId: string;
  vehicleName: string;
  alarmHistoryId: string;
  keyword: string;
  locationDest: string;
  timestamp: Date;
  today: boolean;
  status: EVehicleStatus;
  statusString: string;
  statusChangedTimestamp: Date;
  statusColor: string;
  statusTextColor;
  statusTranslation: string;
  lat: number;
  lng: number;
  hasCoordinates: boolean;
  isNew: boolean;
  hasPosition: boolean;
  currentPosition: VehicleLocationResponse;
  trackingAllowed: boolean;
}

export interface VehicleAssignedAlarmCombined {
  lat: number;
  lng: number;
  color: string;
  emoji: string;
  externalId: string;
  counter: number;
  missionCounterOnSameCoordinates: number;
  clock: string;
  vehicleAssignedToAlarmResponses: VehicleAssignedAlarm[];
  additionalMissionsOnSameCoordinate: VehicleAssignedAlarmCombined[];
}

export interface AlarmMapUpdatedEvent {
  externalId: string;
  lat: number;
  lng: number;
  userId: string;
  keyword: string;
  locationDest: string;
  keywordCategory: string;
  keywordColor: string;
}

export interface VehicleRemovedFromAlarm {
  vehicleId: string;
}

export interface MissionRemovedFromMapResponse {
  externalId: string;
}

export enum EVehicleSortOrder {
  TIMESTAMP = 'TIMESTAMP',
  VEHICLE = 'VEHICLE',
  KEYWORD = 'KEYWORD',
  LOCATION = 'LOCATION'
}

/**
 * An buffer object to edit the alarm details. Keeping the code more clean.
 */
export interface ManualAlarmDetails {
  // buffer variables to keep the set values
  alarmText: string;
  selectedKeyword: string;
  keyword: Keyword;
  selectedKeywordDescription: string;
  selectedStreet: string;
  selectedHouse: string;
  selectedCity: string;
  selectedPostalCode: string;
  selectedAbbreviation: string;
  selectedBuilding: string;
  building: ManualAlarmBuilding;
  address: any;
  lat: number;
  lng: number;
  generateExternalId: boolean;
}

export interface ManualAlarmBuilding {
  id?: string,
  building: string,
  lat?: number,
  lng?: number,
  additional: string,
  abbreviation?: string,
  ident?: string,
  alarmObjectType?: string

}
